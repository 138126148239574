@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

* {
    font-family: 'Prompt', sans-serif;
}

#duean-jam-main-menu a {
    color: #a27345;
}

#duean-jam-main-menu a.active {
    background-color: #080300;
}

#duean-jam-main-menu a:hover {
    background-color: #080300;
    transition: 0.5s;
}

#duean-jam-content .home {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}