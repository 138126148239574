@tailwind base;
@tailwind components;
@tailwind utilities;
/* [FSC] Fullpage JS */

#personal-main-menu a {
  color: rgb(145, 145, 145);
}

#personal-main-menu a.active {
  color: #fff;
  background-color: rgb(15, 15, 15);
}

#personal-main-menu a:hover {
  color: #fff;
  background-color: rgb(15, 15, 15);
  transition: 0.5s;
}

#personal-content .home {
  background-image: url(../assets/personal/BgHome.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}