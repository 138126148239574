@tailwind base;
@tailwind components;
@tailwind utilities;

.fp-section {
    text-align: center;
}

.slide-pointer-block {
    display: flex;
    justify-content: center;
}


.slide-pointer {
    position: fixed;
    height: 50px;
    width: 50px;
    animation: updown 2s ease infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-20%);
  }
}